interface CustomElementChildren extends ChildNode {
  slot?: string;
}

type AvailableSlots = Record<string, true>;

function addSlots(element: HTMLElement, store: AvailableSlots): void {
  /*              */
  Object.keys(store).forEach((key) => {
    delete store[key];
  });

  Array.from(element.childNodes)
    /*                                  */
    .filter((node: ChildNode) => node.nodeType !== Node.COMMENT_NODE)
    .forEach((node: CustomElementChildren) => {
      store[node.slot || "default"] = true;
    });
}

export function useSlots(host: HTMLElement): AvailableSlots {
  const store = $state<AvailableSlots>({});
  addSlots(host, store);

  new MutationObserver(() => {
    addSlots(host, store);
  }).observe(host, { childList: true });

  return store;
}
