<script lang="ts">
  interface Props {
    hidden: boolean;
    direction: "left" | "right";
    onclick: () => void;
  }

  let { hidden, direction, onclick }: Props = $props();

  let removed = $state(hidden);

  let timer: number;
  $effect(() => {
    if (hidden) {
      timer = window.setTimeout(() => {
        removed = true;
      }, 1000);
    } else {
      removed = false;
    }

    return () => clearTimeout(timer);
  });
</script>

<oc-icon-button-v2
  class={`arrow-button arrow-button--${direction}`}
  class:arrow-button--hidden={hidden}
  class:arrow-button--removed={removed}
  icon={`arrow-${direction}`}
  {onclick}
  role="none"
  elevation="200"
  disabled={hidden}
></oc-icon-button-v2>

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  /*          */
  .arrow-button {
    display: none;
  }

  /*                                                 */
  @media (hover: hover) and (pointer: fine) {
    .arrow-button {
      display: block;
      position: absolute;
      top: calc(
        var(--arrow-button-position) - tokens.$oc-component-icon-button-50-background-size / 2
      );

      &--hidden {
        opacity: 0;
      }

      &--removed {
        display: none;
      }

      &--left {
        left: 8px;
      }

      &--right {
        right: 8px;
      }
    }
  }
</style>
