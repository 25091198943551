<script lang="ts">
  /**
 *
 *
 *
 *
 */
  import { tick } from "svelte";

  interface Props {
    /**
 *
 */
    asButton?: boolean;
    /**
 *
 */
    href?: string;
    /**
 *
 *
 */
    base64Href?: string | undefined;
    /**
 *
 */
    interactiveClass?: string | undefined;
    thisElement?: HTMLElement | undefined;
    children?: import("svelte").Snippet;
    [key: string]: unknown;
  }

  let {
    asButton = false,
    href = $bindable(undefined),
    base64Href = $bindable(undefined),
    interactiveClass = undefined,
    thisElement = $bindable(undefined),
    children,
    ...rest
  }: Props = $props();

  /**
 *
 *
 */
  const unmaskHref = async (ev: Event) => {
    /*            */
    ev.currentTarget?.removeEventListener("focus", unmaskHref);

    if (!base64Href) {
      return;
    }
    /*                    */
    href = atob(base64Href);
    base64Href = undefined;

    /*                                  */
    if (ev.constructor.name === "FocusEvent") {
      /*                                     */
      await tick();
      /*                    */
      /*                                                     */
      thisElement?.focus();
    }
  };

  /*                                                               */
  $effect(() => {
    if (thisElement) (thisElement as HTMLAnchorElement).href = href ?? "";
  });

  /*               */
  /*                                         */
  const tag = $derived(base64Href ? "div" : href ? "a" : asButton ? "button" : "div");

  /*                                           */
  const tabindex = $derived(base64Href ? 0 : undefined);
  const role = $derived(base64Href ? "link" : undefined);

  /*                                  */
  const isInteractive = $derived(tabindex === 0 || thisElement?.tabIndex === 0);

  /*                                                                        */
  const className = $derived(`${rest.class ?? ""} ${(isInteractive && interactiveClass) || ""}`);
</script>

<!--order of props is important-->
<!--...$$restProps as late as possible to allow overwriting-->
<!--...$$restProps before class otherwise $$restProps.class overwrites class-->
<svelte:element
  this={tag}
  bind:this={thisElement}
  onfocus={unmaskHref}
  onmouseover={unmaskHref}
  ontouchstart={unmaskHref}
  {tabindex}
  {role}
  {...rest}
  class={className}
>
  {@render children?.()}
</svelte:element>
