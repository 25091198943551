<svelte:options
  customElement={{
    tag: "oc-badge-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      size: { type: "String" },
      variant: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./BadgeV1.types.js";

  let { variant = "primary", size = "100" }: Props = $props();
</script>

<div class="badge {`badge--type-${variant}`} {`badge--size-${size}`}">
  {#if size === "100"}
    {#if variant === "success"}
      <oc-icon-v1 size={"50"} color="currentColor" type="check"></oc-icon-v1>
    {:else}
      <slot />
    {/if}
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    display: inline-block;
    vertical-align: top; /*                                                                                 */
  }

  .badge {
    box-sizing: border-box;
    height: tokens.$oc-component-badge-100-height;
    color: tokens.$oc-component-badge-100-text-color;
    font: tokens.$oc-component-badge-100-font;
    text-align: center;
    line-height: tokens.$oc-component-badge-100-height;
    outline: tokens.$oc-component-badge-border-color solid tokens.$oc-component-badge-border-width;
    border-radius: 8px; /*                                                          */
    padding: 0 4px;

    &--type-primary {
      background-color: tokens.$oc-component-badge-primary-background-color;
    }
    &--type-secondary {
      background-color: tokens.$oc-component-badge-secondary-background-color;
    }
    &--type-success {
      padding: 0 2px;
      background-color: tokens.$oc-component-badge-success-background-color;

      oc-icon-v1 {
        height: 16px;
      }
    }

    &--size-100 {
      min-width: tokens.$oc-component-badge-100-min-width;
    }

    &--size-50 {
      height: tokens.$oc-component-badge-50-height;
      width: tokens.$oc-component-badge-50-height;
      border-radius: 50%;
      background-color: tokens.$oc-component-badge-primary-background-color;
    }
  }
</style>
