/*                                     */

import { tick } from "svelte";
import { derived, writable, readonly, get, type Writable, type Readable } from "svelte/store";
import { stringToDocumentFragment } from "@otto-ec/global-resources/dom";
import { executeInlineScripts } from "@otto-ec/global-resources/hardcore";
import { getPropsFromContent, parseLegacyContent, updatePropsFromContent } from "./contentParser";
import type { OcSheetV1Props } from "../SheetV1.types.g";
import { sheetScope } from "./utils";
import { failure, success, tryRun, type Result } from "../../../../common/utils/effect";

const log = /*           */ sheetScope.scope("external-content");

/**
 *
 *
 *
 *
 */
export function assertContentURL(url: string): Result<Error, string> {
  const [cause, parsed] = tryRun(() => new URL(url, window.location.href));
  if (cause) {
    return failure(new Error(`Could not parse given URL: ${url}`, { cause }));
  }

  /*                                                                     */
  if (
    /*                                              */
    parsed.hostname !== window.location.hostname &&
    !parsed.hostname.endsWith("otto.de")
  ) {
    return failure(new Error(`URL hostname is not allowed in: ${url}`));
  }

  return success(url);
}

/**
 *
 */
export class LoadError extends Error {
  /**
 *
 */
  name = "LoadError";

  /**
 *
 */
  status: number;

  /**
 *
 */
  response: Response;

  /**
 *
 *
 */
  errorContent?: string;

  constructor(message: string, response: Response, errorContent: string | undefined) {
    const msg = response ? `${message}: ${response.status}, ${response.url}` : message;
    super(msg);

    /*                                                */
    this.status = response.status || 300;
    this.response = response;
    this.errorContent = errorContent;
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export async function fetchFromUrl(
  url: string,
  options: { allowedCodes: number[] },
): Promise<Result<Error | LoadError, string>> {
  /*                                        */
  const controller = new AbortController();

  /*                          */
  const [error, response] = await tryRun(fetch, url, {
    redirect: "manual",
    headers: { Accept: "text/html" },
    signal: controller.signal,
  });

  if (error) {
    return failure(error);
  }

  /*                                                                      */
  if (response.ok && !!response.headers.get("Content-Type")?.includes("html")) {
    /*                     */
    return tryRun(() => response.text());
  }

  /*                   */
  if (
    /*                                                                                  */
    !!response.headers.get("Cache-Control")?.includes("no-transform") &&
    /*                                                                                  */
    (options.allowedCodes.length === 0 || options.allowedCodes.includes(response.status))
  ) {
    /*                                                   */
    return failure(new LoadError("Error Response", response, await response.text()));
  }

  /*                                                                         */
  controller.abort();
  return failure(new LoadError("Bad response", response, undefined));
}

/**
 *
 *
 */
export async function processExternalContent(
  externalOptions: Writable<Partial<OcSheetV1Props> | null>,
  externalContent: Writable<DocumentFragment | null>,
  loaded: DocumentFragment | null,
): Promise<void> {
  if (!loaded) return;

  /*                                                                        */
  await tick();

  /*                                                   */
  externalOptions.set(getPropsFromContent(loaded));

  /*                                         */
  externalContent.set(parseLegacyContent(loaded));
}

/**
 *
 *
 */
export function applyContentToHost(
  host: HTMLOcSheetV1Element,
  externalContentApplied: Writable<boolean>,
  content: DocumentFragment | null,
): void {
  if (!content) return;

  /*                     */
  host.innerHTML = "";
  /*                         */
  host.appendChild(content);
  executeInlineScripts(host);

  /*                                                                 */
  /*                                          */
  externalContentApplied.set(true);
}

/**
 *
 */
export async function loadContent(
  rawContent: Writable<string | undefined>,
  rawContentLoaded: Readable<boolean>,
  externalContentError: Writable<Error | LoadError | null>,
  url: string | undefined,
  isActiveSheet: boolean | undefined,
  allowedErrorStatusCodes: number[] | undefined,
): Promise<void> {
  const isAlreadyLoaded = get(rawContentLoaded);
  if (!url || !isActiveSheet || isAlreadyLoaded) {
    /*                                                        */
    /*                                                    */
    return;
  }

  if (!url && isAlreadyLoaded) {
    log.warn("External content has been loaded, and this can not be undone.");
  }

  /*                                                 */
  const [error] = assertContentURL(url);
  if (error) {
    externalContentError.set(error);
    return;
  }

  /*                                 */
  const [fetchError, content] = await fetchFromUrl(url, { allowedCodes: allowedErrorStatusCodes! });
  if (fetchError) {
    externalContentError.set(fetchError);
    return;
  }

  /*                                                    */
  rawContent.set(content);
}

/*                                                                        */
export function useExternalContent(host: HTMLOcSheetV1Element) {
  /*                                                                                  */
  const rawContent = writable<string | undefined>();

  /*                                                           */
  const rawContentLoaded = derived(rawContent, (raw) => typeof raw === "string");

  /*                                                      */
  const loadedContent = derived(rawContent, (contentData) =>
    contentData ? stringToDocumentFragment(contentData) : null,
  );

  /*                                                                                     */
  const externalOptions = writable<Partial<OcSheetV1Props> | null>(null);

  /*                                                                        */
  const externalContent = writable<DocumentFragment | null>(null);

  /*                                                                                           */
  const externalContentApplied = writable(false);

  /*                     */
  loadedContent.subscribe(processExternalContent.bind(null, externalOptions, externalContent));

  /*                                                                  */
  externalContent.subscribe(applyContentToHost.bind(null, host, externalContentApplied));

  /*                                                                        */
  const externalContentError = writable<Error | LoadError | null>(null);

  const loadedErrorContent = derived(externalContentError, (error) => {
    /*                      */
    if (!error) return null;
    log.error("Error loading external content", error);

    if (!(error instanceof LoadError) || !error.errorContent) return null;
    /*                                      */
    return stringToDocumentFragment(error.errorContent);
  });

  /*                                                                               */
  const externalErrorContent = writable<DocumentFragment | null>(null);

  /*                    */
  loadedErrorContent.subscribe(
    processExternalContent.bind(null, externalOptions, externalErrorContent),
  );

  /*                                                                                            */
  externalErrorContent.subscribe(applyContentToHost.bind(null, host, externalContentApplied));

  return {
    /**
 *
 *
 */
    rawContentLoaded,

    /**
 *
 *
 */
    externalContentLoaded: derived(externalContent, ($externalContent) => !!$externalContent),

    /**
 *
 *
 *
 */
    externalContentApplied: readonly(externalContentApplied),

    /**
 *
 *
 */
    externalContentError: readonly(externalContentError),

    /**
 *
 *
 *
 *
 *
 */
    externalErrorContent: derived(externalErrorContent, (e) => !!e),

    /**
 *
 *
 */
    externalOptions,

    /**
 *
 *
 *
 *
 */
    applyExternalOptions(
      forbiddenExternalProps: string[],
      $externalOptions: Partial<OcSheetV1Props> | null,
    ) {
      if ($externalOptions) updatePropsFromContent(host, forbiddenExternalProps, $externalOptions);
    },

    /**
 *
 *
 *
 *
 *
 *
 *
 */

    loadContent: loadContent.bind(null, rawContent, rawContentLoaded, externalContentError),
  };
}
