/*                                                   */

/**
 *
 */
export type Success<R> = [undefined, R];

/**
 *
 */
export type Failure<E> = [E, undefined];

/**
 *
 */
export type Result<E, R> = Failure<E> | Success<R>;

/**
 *
 *
 *
 *
 */
export function failure<E>(e: E): Failure<E> {
  return [e, undefined];
}

/**
 *
 *
 *
 *
 */
export function success<R>(r: R): Success<R> {
  return [undefined, r];
}

export type TryRun<E extends Error = Error> = {
  /**
 *
 *
 *
 *
 *
 */
  <A extends any[], R>(
    fn: (...args: A) => R,
    ...args: A
  ): R extends Promise<infer U> ? Promise<Result<E, U>> : Result<E, R>;

  /**
 *
 */
  withError<E2 extends Error>(): TryRun<E2>;
};

/**
 *
 *
 */
export const tryRun: TryRun = (<A extends any[], R>(fn: (...args: A) => R, ...args: A) => {
  try {
    const res = fn(...args);
    if (res === Promise.resolve(res)) {
      return (res as Promise<any>).then(success).catch(failure);
    }
    return success(res);
  } catch (e) {
    return failure(e);
  }
}) as TryRun;
tryRun.withError = <E extends Error>() => tryRun as TryRun<E>;

export type AsTryRun<E extends Error = Error> = {
  /**
 *
 *
 *
 *
 *
 */
  <A extends any[], R>(
    fn: (...args: A) => R,
  ): (...args: A) => R extends Promise<infer U> ? Promise<Result<E, U>> : Result<E, R>;

  /**
 *
 */
  withError<E2 extends Error>(): AsTryRun<E2>;
};

/**
 *
 *
 */
export const asTryRun: AsTryRun = (<A extends any[], R>(fn: (...args: A) => R) =>
  (...args: A) =>
    tryRun(fn, ...args)) as AsTryRun;
asTryRun.withError = <E extends Error>() => asTryRun as AsTryRun<E>;
