import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
import type { CamelCase } from "type-fest";
import { get, type Readable } from "svelte/store";
import { camelcasify } from "@otto-ec/assets-core-utils/string";
import { sheet } from "../index";
import type { Events } from "../SheetV1.types";
import type { LoadError } from "./externalContent";

/**
 *
 */
export type GetSheetDetailByName<T extends keyof Events> =
  Events[T] extends CustomEvent<infer U> ? U : never;

type GetDetailByEventName<T> = T extends keyof Events ? GetSheetDetailByName<T> : never;

type GetSheetDetailById<T extends EventsKeys> =
  /*                                                          */
  (typeof EVENTS)[T] extends AsEventPair<any> ? GetDetailByEventName<(typeof EVENTS)[T][0]> : never;

type AsEventKey<T> = T extends `oc-${infer U}` ? U : never;

type AsEventPair<T extends string> = [`oc-${T}`, `${CamelCase<T>}Event`];

function eventPair<T extends string>(key: T): AsEventPair<T> {
  return [`oc-${key}`, `${camelcasify(key)}Event`] as const;
}

type EventsKeys = 0 | 1 | 2 | 3 | 4;

const EVENTS = [
  eventPair("open"),
  eventPair("close"),
  eventPair("switch"),
  eventPair("content-loaded"),
  eventPair("content-loading-error"),
] as const satisfies AsEventPair<AsEventKey<keyof Events>>[];

const openEvent = 0 satisfies EventsKeys;
const closeEvent = 1 satisfies EventsKeys;
const switchEvent = 2 satisfies EventsKeys;
const contentLoadedEvent = 3 satisfies EventsKeys;
const contentLoadingErrorEvent = 4 satisfies EventsKeys;

type CloseTypeKeys = 0 | 1 | 2 | 3 | 4 | 5;

export type CloseTypes =
  | "closedWithX"
  | "closedWithCurtainClick"
  | "closedWithDrag"
  | "closedWithEscape"
  | "closedProgrammatically"
  | "closedWithBackNavigation";

export const CLOSE_TYPES = [
  "closedWithX",
  "closedWithCurtainClick",
  "closedWithDrag",
  "closedWithEscape",
  "closedProgrammatically",
  "closedWithBackNavigation",
] as const satisfies CloseTypes[];

export const CLOSED_WITH_X = 0 satisfies CloseTypeKeys;
export const CLOSED_WITH_CURTAIN_CLICK = 1 satisfies CloseTypeKeys;
export const CLOSED_WITH_DRAG = 2 satisfies CloseTypeKeys;
export const CLOSED_WITH_ESCAPE = 3 satisfies CloseTypeKeys;
export const CLOSED_PROGRAMMATICALLY = 4 satisfies CloseTypeKeys;
export const CLOSED_WITH_BACK_NAVIGATION = 5 satisfies CloseTypeKeys;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function useSheetEvents(
  host: HTMLOcSheetV1Element,
  openSheet: Readable<HTMLOcSheetV1Element | null>,
  sheetElementVisible: Readable<boolean>,
  isSwitching: Readable<boolean>,
  requestedClose: Readable<CloseTypes | null>,
  externalContentApplied: Readable<boolean>,
  externalContentError: Readable<Error | LoadError | null>,
  extraData: Record<string, unknown> | null,
): void {
  const dispatch = useEventDispatcher<Events>(host);

  /**
 *
 */
  function emitEvent<T extends EventsKeys>(
    eventId: T,
    payload: Omit<GetSheetDetailById<T>, "instance" | "extraData">,
  ): void {
    /*                                        */
    dispatch(EVENTS[eventId][0], { ...payload, instance: host, extraData });

    /*                                                         */
    /*                                           */
    const openInstance = get(openSheet);
    if (openInstance && openInstance !== host) return;

    /*                                                                            */
    /*                                                                                     */
    sheet[EVENTS[eventId][1]].emit({ ...payload, instance: openInstance ?? host, extraData });
  }

  /*                                                    */
  /*                                                                                */
  sheetElementVisible.subscribe((visible) => {
    if (visible) emitEvent(get(isSwitching) ? switchEvent : openEvent, {});
  });

  /*                                                              */
  requestedClose.subscribe((closeType) => {
    if (closeType) emitEvent(closeEvent, { closeType });
  });

  /*                                                                       */
  /*               */
  externalContentApplied.subscribe((applied) => {
    if (applied) emitEvent(contentLoadedEvent, {});
  });

  /*                                                          */
  /*                                                                      */
  externalContentError.subscribe((error) => {
    if (error) emitEvent(contentLoadingErrorEvent, { error });
  });
}
