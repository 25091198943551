import { closeContext, createContext } from "@otto-ec/tracking-bct";
import type { Readable } from "svelte/store";
import { sheetScope } from "./utils";

const log = /*           */ sheetScope.scope("tracking");

/**
 *
 *
 *
 *
 *
 *
 */
export function useTrackingContext(
  isActiveSheet: Readable<boolean>,
  rawContentLoaded: Readable<boolean>,
) {
  let sheetId: string;
  let contentUrl: string | undefined;

  rawContentLoaded.subscribe((loaded) => {
    /*                                                 */
    /*                                                     */
    if (!loaded || !contentUrl) {
      return;
    }

    if (import.meta.env.DEV) {
      log.info("Creating tracking context for sheet", sheetId);
    }
    createContext(sheetId, contentUrl, { ts_OttoComponent: ["sheet@v1"] });

    /*                                                            */
    /*                                 */
    const closeUnsub = isActiveSheet.subscribe((active) => {
      if (active) {
        return;
      }

      if (import.meta.env.DEV) {
        log.info("Closing tracking context for sheet", sheetId);
      }
      closeContext();
      closeUnsub();
    });
  });

  return function update(id: string, externalContentUrl: string | undefined) {
    sheetId = id;
    contentUrl = externalContentUrl;
  };
}
