import { nanoid } from "@otto-ec/global-resources/misc";
import { componentsScope } from "../../../../common/utils/logger.js";
import type { Props } from "../SheetV1.types.js";

export const sheetScope = /*           */ componentsScope.scope("sheet-v1");

/*                      */
export function computeSheetId(): string {
  const res = nanoid();
  sheetScope.warn("sheet id is missing, use computed one:", res);
  return res;
}

export const sheetProps = [
  /*                     */
  "id",
  "url",
  "base64Url",
  "open",
  "allowedErrorStatusCodes",
  "forbiddenExternalProps",
  "extraData",
  /*            */
  "headline",
  "hideHeader",
  "hideCloseButton",
  "fullHeight",
  "ocAriaLabel",
  "noContentPadding",
] as const satisfies (keyof Props)[];

export function applyProps(sheet: HTMLOcSheetV1Element, config: Partial<Props>): string {
  Object.entries(config).forEach(([key, value]) => {
    /*                                                     */
    sheet[key] = value;
  });

  /*                             */
  sheet.id ??= nanoid();
  sheet.open ??= true;

  return sheet.id;
}
