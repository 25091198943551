<svelte:options
  customElement={{
    tag: "oc-accordion-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      title: { type: "String" },
      expanded: { type: "Boolean" },
      hideDivider: { type: "Boolean", attribute: "hide-divider" },
    },
  }}
/>

<script lang="ts">
  import { tick } from "svelte";
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import type { Events, Props } from "./AccordionV1.types";
  import { onAnimationEnd } from "../../../common/utils/dom";

  let { title = "", expanded = false, hideDivider = false }: Props = $props();

  const Host = $host();
  const dispatch = useEventDispatcher<Events>(Host);

  let contentElement = $state<HTMLElement>();
  /*                                     */
  let isExpanded = $state<boolean>(expanded);
  /*                                */
  let isHidden = $state<boolean>(!expanded);

  function onClick(ev: Event) {
    ev.preventDefault();

    /*                                                                        */
    /*                                   */
    const nextExpanded = !expanded;

    /*                                             */
    /*                                                     */
    if (!dispatch(nextExpanded ? "oc-open" : "oc-close")) return;

    /*                                                        */
    /*                                   */
    expanded = nextExpanded;
  }

  async function awaitAnimationFrame(cb: () => void) {
    /*                                                  */
    await tick();
    /*                              */
    await onAnimationEnd(contentElement!);
    /*                                    */
    cb();
  }

  $effect(() => {
    /*                                 */
    if (!expanded || !isHidden) return;

    /*                              */
    isHidden = false;

    /*                                                  */
    awaitAnimationFrame(() => {
      isExpanded = true;
    });
  });

  $effect(() => {
    /*                                */
    if (expanded || !isExpanded) return;

    /*                         */
    isExpanded = false;

    /*                                                              */
    awaitAnimationFrame(() => {
      isHidden = true;
    });
  });
</script>

<div class="panel">
  <span id="panel-title">
    <button
      onclick={onClick}
      class="oc-row-v1 oc-row-v1--interactive trigger"
      class:oc-row-v1--hide-divider={hideDivider}
      aria-expanded={isExpanded}
      aria-controls="content"
    >
      {title}
      <oc-icon-v1 type="arrow-down"></oc-icon-v1>
    </button>
  </span>
  <div
    bind:this={contentElement}
    class="content"
    class:closed={!isExpanded}
    role="region"
    aria-labelledby="panel-title"
    id="content"
    hidden={isHidden}
  >
    <div>
      <slot />
    </div>
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../css-only-components/row/v1/RowV1.global" as row;

  .panel {
    .trigger {
      display: grid;
      font: tokens.$oc-component-accordion-label-font;
      color: tokens.$oc-component-accordion-label-color;
      grid-template-columns: 1fr 24px;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      align-items: center;

      grid-template-areas: "content icon";

      > * {
        grid-area: content;
      }

      > .title {
        padding-right: tokens.$oc-semantic-spacing-100;
      }

      & > oc-icon-v1 {
        fill: tokens.$oc-component-accordion-icon-color;
        grid-area: icon;
        transition: transform tokens.$oc-component-accordion-transition-duration
          tokens.$oc-component-accordion-transition-easing;
      }

      &[aria-expanded="true"] oc-icon-v1 {
        transform: rotate(-180deg);
      }
    }
  }

  .content {
    display: grid;
    grid-template-rows: 1fr;
    padding: tokens.$oc-component-accordion-gap-y 0 tokens.$oc-component-accordion-spacing-bottom 0;
    transition: grid-template-rows tokens.$oc-component-accordion-transition-duration
      tokens.$oc-component-accordion-transition-easing;
  }

  .content.closed {
    padding: 0;
    grid-template-rows: 0fr;
  }

  [hidden] {
    display: none !important;
  }

  .content > div {
    overflow: hidden;
  }
</style>
