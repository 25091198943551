/*                                 */
import { get, type Readable, type Unsubscriber } from "svelte/store";

import { toggle } from "@otto-ec/global-resources/toggle";
import type { SetRequired } from "type-fest";
import type { back, close, initHistoryStack, next } from "./sheetHistory.js";
import { sheetScope } from "./utils.js";
import { CLOSE_TYPES, CLOSED_WITH_BACK_NAVIGATION } from "./events.js";
import { create } from "../SheetV1.api.js";
import type { OcSheetV1Props } from "../SheetV1.types.g.js";
import type { Props } from "../SheetV1.types.js";

/*            */
const windowHistory = window.history;
const scope = /*           */ sheetScope.scope("window-history");
const initHistoryLog = /*           */ scope.scope("initHistory");
const popHistoryStateLog = /*           */ scope.scope("popHistoryState");
const pushHistoryStateLog = /*           */ scope.scope("pushHistoryState");
const handlePopStateLog = /*           */ scope.scope("handlePopState");
const handleStackChangeLog = /*           */ scope.scope("handleStackChange");
const handleInstancePropsLog = /*           */ scope.scope("handleInstanceProps");
const createSheetInstanceIfNotExistsLog = /*           */ scope.scope(
  "createSheetInstanceIfNotExists",
);

export type SheetHistoryState = {
  /**
 *
 */
  sheetV1Id?: string;
  /**
 *
 */
  sheetV1Ids?: string[];
  /**
 *
 *
 */
  sheetV1Config?: OcSheetV1Props;
};

/**
 *
 *
 *
 */
const constrols = [true, true, []] as [
  isWindowHistoryActive: boolean,
  isSheetHistoryActive: boolean,
  sheetIdsToPop: string[],
];
const isWindowHistoryActive = 0 satisfies keyof typeof constrols;
const isSheetHistoryActive = 1 satisfies keyof typeof constrols;
const sheetIdsToPop = 2 satisfies keyof typeof constrols;

/**
 *
 */
export function sheetHistoryControlState(): typeof constrols {
  return [...constrols];
}

const popstateEvent = "popstate";

/**
 *
 *
 *
 *
 */
export function popHistoryStateHandler(e: PopStateEvent): void {
  /*                                                               */
  popHistoryState(false, e.state);
}

/**
 *
 *
 *
 *
 *
 */
export function popHistoryState(initialize: boolean, state: SheetHistoryState | undefined): void {
  const sheetIdToPop = constrols[sheetIdsToPop].shift();
  const goBack = !!sheetIdToPop && state?.sheetV1Id === sheetIdToPop;

  if (import.meta.env.DEV)
    popHistoryStateLog.info(
      "popping history state, goback: ",
      goBack,
      "state:",
      state,
      "sheetId",
      sheetIdToPop,
    );

  if (import.meta.env.DEV)
    popHistoryStateLog.info("cleaning up window history, init:", initialize, "goback:", goBack);

  if (initialize && goBack) {
    if (import.meta.env.DEV) popHistoryStateLog.info("initialize history cleanup");
    constrols[isWindowHistoryActive] = false;
    window.addEventListener(popstateEvent, popHistoryStateHandler);
  }

  if (goBack) {
    if (import.meta.env.DEV) popHistoryStateLog.info("history go back requested");
    windowHistory.back();
    return;
  }

  if (!initialize) {
    if (import.meta.env.DEV) popHistoryStateLog.info("remove history cleanup");

    window.removeEventListener(popstateEvent, popHistoryStateHandler);
    constrols[sheetIdsToPop].splice(0);
    constrols[isWindowHistoryActive] = true;
  }
}

/**
 *
 *
 *
 *
 *
 */
export function pushHistoryState(
  current: string[],
  previous: string[],
  state: SheetHistoryState | undefined,
): void {
  if (import.meta.env.DEV) pushHistoryStateLog.info("pushing history", current, previous, state);

  /*                            */
  current.slice(previous.length).forEach((sheetId) => {
    if (import.meta.env.DEV)
      pushHistoryStateLog.info("check for sheet", sheetId, current.slice(previous.length));

    /*                                                               */
    if (state?.sheetV1Id === sheetId) {
      return;
    }

    if (import.meta.env.DEV) pushHistoryStateLog.info("push next sheet", sheetId);
    windowHistory.pushState(
      { sheetV1Id: sheetId, sheetV1Ids: current } as SheetHistoryState,
      "",
      window.location.href,
    );
  });
}

/**
 *
 *
 *
 *
 *
 */
/*                                                          */
export async function invokeSheetApi<F extends (...a: any[]) => unknown>(
  fn: F,
  ...args: Parameters<F>
): Promise<void> {
  if (import.meta.env.DEV) handlePopStateLog.info("invoke sheet api", fn.name, args);
  constrols[isSheetHistoryActive] = false;
  await fn(...args);
  constrols[isSheetHistoryActive] = true;
}

export function createSheetInstanceIfNotExists(
  state: SetRequired<SheetHistoryState, "sheetV1Id">,
  createFn: typeof create,
): void {
  const sheetInstance = document.getElementById(state.sheetV1Id);
  if (!sheetInstance) {
    if (import.meta.env.DEV) createSheetInstanceIfNotExistsLog.info("create sheet instance", state);
    invokeSheetApi(createFn, { ...state.sheetV1Config, open: false });
  }
}

/**
 *
 *
 *
 *
 *
 */
export function handlePopState(
  sheetHistory: Readable<[current: string[], previous: string[]]>,
  closeFn: typeof close,
  nextFn: typeof next,
  backFn: typeof back,
  createFn: typeof create,
  state: SheetHistoryState | undefined,
): void | Promise<void | void[]> {
  if (!constrols[isWindowHistoryActive] || !state) {
    if (import.meta.env.DEV) handlePopStateLog.info("history update not active, skip", state);
    return undefined;
  }

  if (import.meta.env.DEV) handlePopStateLog.info("check current history state", state);

  const [current] = get(sheetHistory);
  const { sheetV1Id: sheetId } = state;

  if (!sheetId && current.length > 0) {
    if (import.meta.env.DEV) handlePopStateLog.info("no sheet id in window.history, close all");

    return invokeSheetApi(closeFn, CLOSE_TYPES[CLOSED_WITH_BACK_NAVIGATION]);
  }

  if (!sheetId) {
    if (import.meta.env.DEV)
      handlePopStateLog.info("no sheet id in window.history, skip pop state");

    return undefined;
  }

  if (import.meta.env.DEV) handlePopStateLog.info("open from state, ensure sheet instance", state);
  createSheetInstanceIfNotExists(state as never, createFn);

  if (import.meta.env.DEV) handlePopStateLog.info("check lastIdIndex", sheetId, current);
  const lastIdIndex = current.lastIndexOf(sheetId);
  if (sheetId && lastIdIndex === -1) {
    if (import.meta.env.DEV)
      handlePopStateLog.info(
        "sheetId not found in history, but instance is in dom. open instance",
        sheetId,
      );

    return invokeSheetApi(nextFn, sheetId);
  }

  if (lastIdIndex !== -1) {
    const popIds = current.slice(lastIdIndex + 1);
    if (import.meta.env.DEV) handlePopStateLog.info("call back method for ids:", popIds);

    return Promise.all(
      popIds.map((popId) => {
        if (import.meta.env.DEV) handlePopStateLog.info("call back method fo sheet:", popId);
        return invokeSheetApi(backFn);
      }),
    );
  }

  return undefined;
}

export function restoreHistoryState(state: SheetHistoryState | undefined): void {
  const curentState = windowHistory.state as SheetHistoryState | undefined;

  if (curentState?.sheetV1Id !== state?.sheetV1Id) {
    if (import.meta.env.DEV)
      initHistoryLog.info("restore window history state", curentState, "back to the init", state);

    windowHistory.replaceState(state, "", window.location.href);
    return;
  }

  if (import.meta.env.DEV)
    initHistoryLog.info("history state not yet invalidated", curentState, state);

  setTimeout(() => restoreHistoryState(state), 10);
}

/**
 *
 *
 */
export function initSheetHistory(
  inifinitStackFn: typeof initHistoryStack,
  state: SheetHistoryState | undefined,
  createFn: typeof create,
): void {
  if (import.meta.env.DEV) initHistoryLog.info("init sheet history", state);

  if (!state?.sheetV1Ids?.length || !state.sheetV1Id) {
    if (import.meta.env.DEV) initHistoryLog.info("nothing to restore", state);
    return;
  }

  if (import.meta.env.DEV) initHistoryLog.info("ensure sheet instance", state);
  createSheetInstanceIfNotExists(state as never, createFn);

  if (import.meta.env.DEV) initHistoryLog.info("restore sheet history state", state.sheetV1Ids);
  inifinitStackFn(state.sheetV1Ids);

  /*                                                           */
  restoreHistoryState(windowHistory.state);
}

/**
 *
 *
 */
export function handleStackChange([current, previous]: [
  current: string[],
  previous: string[],
]): void {
  if (import.meta.env.DEV) handleStackChangeLog.info("sheetHistory changed", current, previous);
  if (!constrols[isSheetHistoryActive]) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("history update not active, skip", current, previous);
    return;
  }

  /*                                                                   */
  /*                                            */
  const state: SheetHistoryState | undefined = windowHistory.state;

  /*                           */
  if (current.length < previous.length) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("sheet removed, begin pop history", previous.slice(current.length));

    constrols[sheetIdsToPop] = previous.slice(current.length).reverse();
    popHistoryState(true, state);
    return;
  }

  /*                              */
  if (current.length > previous.length) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("sheet added, check history", current.slice(previous.length));

    pushHistoryState(current, previous, state);
    return;
  }

  if (import.meta.env.DEV)
    handleStackChangeLog.info("history hasnt been changed", current, previous);
}

/**
 *
 *
 *
 *
 *
 */
export function handleInstanceProps(openSheetProps: Props | null): void {
  if (!constrols[isSheetHistoryActive]) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("history update not active, skip", openSheetProps);
    return;
  }

  const state = windowHistory.state as SheetHistoryState | undefined;

  if (!state?.sheetV1Id || !openSheetProps?.id || state.sheetV1Id !== openSheetProps.id) {
    if (import.meta.env.DEV)
      handleInstancePropsLog.info("skip instance props update", state, openSheetProps);
    return;
  }

  if (import.meta.env.DEV)
    handleInstancePropsLog.info("update instance props in history", openSheetProps);
  windowHistory.replaceState(
    { ...state, sheetConfig: openSheetProps } as SheetHistoryState,
    "",
    window.location.href,
  );
}
/**
 *
 *
 */
export function useWindowHistory(
  sheetHistory: Readable<[current: string[], previous: string[]]>,
  openSheetProps: Readable<Props | null>,
  initStackFn: typeof initHistoryStack,
  createFn: typeof create,
  nextFn: typeof next,
  backFn: typeof back,
  closeFn: typeof close,
): Unsubscriber {
  /*                           */
  if (!import.meta.env.STORYBOOK && !toggle.get("oc_sheet_v1_use_window_history", false)) {
    scope.info("window history integration is disabled by toggle: oc_sheet_v1_use_window_history");
    return () => {};
  }

  /*                                                             */
  const state = windowHistory.state as SheetHistoryState | undefined;
  initSheetHistory(initStackFn, state, createFn);

  /*                                                  */
  function popStateHandler(e: PopStateEvent): void {
    handlePopState(sheetHistory, closeFn, nextFn, backFn, createFn, e.state);
  }
  window.addEventListener(popstateEvent, popStateHandler);

  /*                                                        */
  const unsetHist = sheetHistory.subscribe(handleStackChange);

  /*                                                                */
  const unsetProps = openSheetProps.subscribe(handleInstanceProps);

  return () => {
    window.removeEventListener(popstateEvent, popStateHandler);
    unsetProps();
    unsetHist();
  };
}
