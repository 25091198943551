import { stringToDocumentFragment } from "@otto-ec/global-resources/dom";
import { parseParameters } from "@otto-ec/global-resources/attribute-parser";

/**
 *
 *
 */
import { tryRun } from "../../../../common/utils/effect";
import type { OcSheetV1Props } from "../SheetV1.types.g";

const HEADLINE_SLOT_ATTR = 'slot="headline"';

/**
 *
 *
 *
 *
 *
 *
 */
export function parseLegacyContent(content: DocumentFragment): DocumentFragment {
  /*                                                      */
  /*                                                           */
  if (content.querySelector(`[${HEADLINE_SLOT_ATTR}]`)) {
    return content;
  }

  /*                                     */
  const headline = content.querySelector<HTMLElement>(`[data-title]`)?.dataset.title;
  if (!headline) {
    return content;
  }

  /*                                                            */
  const headlineSlotFragment = stringToDocumentFragment(
    `<h3 class="oc-headline-100" ${HEADLINE_SLOT_ATTR}>${headline}</h3>`,
  );
  content.append(headlineSlotFragment);
  return content;
}

export function getPropsFromContent(content: DocumentFragment): Partial<OcSheetV1Props> {
  const script = content.querySelector<HTMLScriptElement>("script[data-oc-sheet-v1]");
  if (!script) {
    return {};
  }

  /*                                       */
  const [, extraParameters = {}] = tryRun(
    (data) => JSON.parse(data) as Record<string, unknown>,
    script.textContent!,
  );

  /*                    */
  content.removeChild(script);

  return parseParameters("data-oc-sheet-v1", "configure", script, undefined, {
    extraParameters,
  });
}

export function updatePropsFromContent(
  host: HTMLOcSheetV1Element,
  forbiddenExternalProps: string[],
  props: Partial<OcSheetV1Props>,
): void {
  Object.entries(props).forEach(([key, value]) => {
    if (!forbiddenExternalProps.includes(key)) {
      host[key as never] = value as never;
    }
  });
}
