/*                                     */
import type { EventTopic, ExtendedPromise } from "@otto-ec/global-resources/nexus";
import { components, type OttoComponents } from "../../../common/component.js";
import type { GetSheetDetailByName } from "./lib/events";
import type { OcSheetV1Props } from "./SheetV1.types.g";

declare global {
  interface OttoComponentApi {
    /**
 *
 *
 */
    sheetV1: {
      /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
      open: (sheetID: string) => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 */
      close: () => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 *
 *
 *
 */
      back: () => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 */
      create: (
        config: Partial<OcSheetV1Props> | undefined,
      ) => ExtendedPromise<HTMLOcSheetV1Element>;

      /**
 *
 */
      getOpenSheet: () => ExtendedPromise<HTMLOcSheetV1Element | null>;

      /**
 *
 *
 */
      getContent: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 *
 */
      getHeader: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 *
 */
      getActions: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 *
 *
 *
 *
 *
 *
 */
      openEvent: EventTopic<GetSheetDetailByName<"oc-open">>;

      /**
 *
 *
 *
 */
      closeEvent: EventTopic<GetSheetDetailByName<"oc-close">>;

      /**
 *
 *
 *
 *
 *
 *
 */
      switchEvent: EventTopic<GetSheetDetailByName<"oc-switch">>;

      /**
 *
 *
 *
 *
 *
 */
      contentLoadedEvent: EventTopic<GetSheetDetailByName<"oc-content-loaded">>;

      /**
 *
 *
 *
 *
 */
      contentLoadingErrorEvent: EventTopic<GetSheetDetailByName<"oc-content-loading-error">>;
    };
  }
}

/*                                   */
export type SheetV1 = OttoComponents["sheetV1"];
/*                                                  */
export const sheet: SheetV1 = components.sheetV1;
/*                                                */
export const open: SheetV1["open"] = sheet.open;
/*                                                 */
export const close: SheetV1["close"] = sheet.close;
/*                                                */
export const back: SheetV1["back"] = sheet.back;
/*                                                  */
export const create: SheetV1["create"] = sheet.create;
/*                                                        */
export const getOpenSheet: SheetV1["getOpenSheet"] = sheet.getOpenSheet;
/*                                                     */
export const getHeader: SheetV1["getHeader"] = sheet.getHeader;
/*                                                      */
export const getContent: SheetV1["getContent"] = sheet.getContent;
/*                                                      */
export const getActions: SheetV1["getActions"] = sheet.getActions;
/*                                                        */
export const openEvent: SheetV1["openEvent"] = sheet.openEvent;
/*                                                         */
export const closeEvent: SheetV1["closeEvent"] = sheet.closeEvent;
/*                                                          */
export const switchEvent: SheetV1["switchEvent"] = sheet.switchEvent;
/*                                                                 */
export const contentLoadedEvent: SheetV1["contentLoadedEvent"] = sheet.contentLoadedEvent;
/*                                                                       */
export const contentLoadingErrorEvent: SheetV1["contentLoadingErrorEvent"] =
  sheet.contentLoadingErrorEvent;
