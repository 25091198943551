<svelte:options
  customElement={{
    tag: "oc-chip-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
    }),
    props: {
      inverted: { type: "Boolean" },
      size: { type: "String" },
      removable: { type: "Boolean" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      icon: { type: "String" },
      showCheckIcon: { type: "Boolean", attribute: "show-check-icon" },
      singleSelection: { type: "Boolean", attribute: "single-selection" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";

  import type { Events, Props } from "./ChipV1.types";

  let {
    inverted = false,
    size = "100",
    removable = false,
    disabled = false,
    name = undefined,
    value = "on",
    checked = false,
    showCheckIcon = false,
    icon = undefined,
    singleSelection = false,
    ocAriaLabel = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();

  let dispatch = useEventDispatcher<Events>(Host);

  let leftIcon = $derived(checked && showCheckIcon ? "check" : icon);

  export function resetForm() {
    /*                            */
    if (!removable) {
      checked = Host.hasAttribute("checked");
    }
  }

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });
  /*                                         */
  let role = $derived(removable ? "button" : singleSelection ? "radio" : "checkbox");

  const onCheckToggle = (event: MouseEvent | KeyboardEvent) => {
    if (disabled) return;

    if (removable) {
      if (
        (event instanceof KeyboardEvent && ["Space", "Enter"].includes(event.code)) ||
        event.type === "click"
      ) {
        dispatch("oc-remove");
      }
    } else if (
      (event instanceof KeyboardEvent && event.code === "Space") ||
      event.type === "click"
    ) {
      /*                                        */
      if (singleSelection && checked) return;
      checked = !checked;
    }

    /*                             */
    if (event instanceof KeyboardEvent && event.code === "Space") {
      event.preventDefault();
    }
  };

  /*                      */
  $effect(() => {
    if (singleSelection && name && checked && internals) {
      document
        .querySelectorAll<HTMLOcChipV1Element>(`oc-chip-v1[name="${name}"]`)
        .forEach((chip) => {
          const isSelf = chip.value === value;
          if (!isSelf && chip.checked) {
            chip.checked = false;
          }
        });
    }
  });
</script>

<!-- svelte-ignore a11y_no_noninteractive_tabindex -->
<div
  class="chip chip--size-{size}"
  class:chip--inverted={inverted}
  onclick={onCheckToggle}
  onkeydown={onCheckToggle}
  {role}
  tabindex={disabled ? -1 : 0}
  aria-label={ocAriaLabel}
  aria-disabled={disabled}
  aria-checked={removable ? undefined : `${checked}`}
>
  <!-- Icon Left -->
  {#if leftIcon}
    <oc-icon-v1 class="chip__icon chip__icon--{size}" type={leftIcon}></oc-icon-v1>
  {/if}

  <label class="chip__label">
    <slot />
    {#if removable}
      <span class="chip__aria-remove">entfernen</span>
    {/if}
  </label>

  <!-- Removable Icon Right -->
  {#if removable}
    <oc-icon-v1 class="chip__icon chip__icon--removable chip__icon--{size}" type={"close"}
    ></oc-icon-v1>
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: inline-block;
  }

  .chip {
    position: relative;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: tokens.$oc-component-chip-min-width;

    background-color: tokens.$oc-component-chip-default-background-color;
    color: tokens.$oc-component-chip-text-color;
    cursor: pointer;
    user-select: none;

    & {
      @include mixins.focus-styles(20px);
    }

    @media (hover: hover) {
      &:hover {
        background-color: tokens.$oc-component-chip-default-background-color-hover;
      }
    }

    &:active {
      background-color: tokens.$oc-component-chip-default-background-color-active;
    }

    .chip__label {
      pointer-events: none;
      user-select: none;
      font-size: tokens.$oc-component-chip-100-font;
    }

    &--size-100 {
      padding: tokens.$oc-component-chip-100-spacing-y tokens.$oc-component-chip-100-spacing-x;
      border-radius: 20px;
      gap: tokens.$oc-component-chip-100-gap-x;
      font: tokens.$oc-component-chip-100-font;
      height: tokens.$oc-component-chip-100-height;
    }

    &--size-200 {
      padding: tokens.$oc-component-chip-200-spacing-y tokens.$oc-component-chip-200-spacing-x;
      border-radius: 24px;
      gap: tokens.$oc-component-chip-200-gap-x;
      font: tokens.$oc-component-chip-200-font;
      height: tokens.$oc-component-chip-200-height;

      & {
        @include mixins.focus-styles(24px);
      }
    }

    &[aria-checked="true"] {
      background-color: tokens.$oc-component-chip-selected-background-color;
      font-weight: tokens.$oc-component-chip-selected-font-weight;

      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-chip-selected-background-color-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-chip-selected-background-color-active;
      }
    }

    &[aria-disabled="true"] {
      background-color: tokens.$oc-component-chip-disabled-background-color;
      color: tokens.$oc-component-chip-disabled-icon-color;
      pointer-events: none;

      &[aria-checked] {
        font-weight: normal;
      }

      .chip__icon {
        fill: tokens.$oc-component-chip-disabled-icon-color;
      }
    }

    &__icon {
      height: tokens.$oc-component-chip-100-icon-size;
      width: tokens.$oc-component-chip-100-icon-size;
      pointer-events: none;

      &--200 {
        align-self: auto;
        height: tokens.$oc-component-chip-200-icon-size;
        width: tokens.$oc-component-chip-200-icon-size;
      }
    }

    &--inverted {
      background-color: tokens.$oc-component-chip-inverted-background-color;

      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-chip-inverted-background-color-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-chip-inverted-background-color-active;
      }
    }

    &__aria-remove {
      @include mixins.visually-hidden();
    }
  }
</style>
