/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function useFullPathHrefAccessor(
  host: HTMLElement,
  getHref: () => string | undefined,
  setHref: (value: string) => unknown,
): void {
  Object.defineProperty(host, "href", {
    get() {
      const href = getHref();
      if (!href) return href; /*                */

      /*                                           */
      const a = document.createElement("a");
      a.href = href; /*       */

      /*                           */
      return a.href;
    },
    set: setHref,
  });
}
