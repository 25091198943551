<svelte:options
  customElement={{
    tag: "oc-icon-v1",
    props: {
      color: { type: "String" },
      size: { type: "String", reflect: true },
      type: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./IconV1.types";

  let { type, size = "100", color = undefined, ocAriaLabel = undefined }: Props = $props();

  let iconIdentifier = $derived(`pl_icon_${type}${size === "50" ? "50" : ""}`);
  let href = $derived(`/assets-static/icons/${iconIdentifier}.svg#${iconIdentifier}`);
  let isAriaHidden = $derived(!ocAriaLabel);
</script>

{#if type}
  <svg class="icon" style:--color={color} aria-hidden={isAriaHidden} aria-label={ocAriaLabel}>
    <use {href} />
  </svg>
{/if}

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    /*        */
    --color: unset;

    @include mixins.no-tap-highlight();
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  :host([size="50"]) {
    width: tokens.$oc-component-icon-50-default-size;
    height: tokens.$oc-component-icon-50-default-size;
  }

  :host([size="100"]) {
    width: tokens.$oc-component-icon-100-default-size;
    height: tokens.$oc-component-icon-100-default-size;
  }

  .icon {
    display: block;
    height: 100%;
    width: 100%;
    fill: var(--color);
  }
</style>
