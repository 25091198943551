<svelte:options
  customElement={{
    tag: "oc-link-v2",
    shadow: "none",
    /*                       */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      variant: { type: "String" },
      size: { type: "String", reflect: true },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      asButton: { type: "Boolean", attribute: "as-button" },
      target: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";

  import type { Props } from "./LinkV2.types.js";

  let {
    variant = "primary",
    size = "100",
    href = undefined,
    base64Href = undefined,
    asButton = false,
    target = undefined,
    ocAriaLabel = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );
</script>

<InteractiveElement
  {asButton}
  bind:href
  {base64Href}
  {target}
  class={`link link--${variant}`}
  aria-label={ocAriaLabel}
>
  <slot />
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  /*                                                                                              */
  :host {
    font: tokens.$oc-component-link-100-font;
  }

  :host([size="inherit"]) {
    font: inherit;
  }

  :host([size="50"]) {
    font: tokens.$oc-component-link-50-font;
  }

  :host([size="75"]) {
    font: tokens.$oc-component-link-75-font;
  }

  :host([size="125"]) {
    font: tokens.$oc-component-link-125-font;
  }

  .link {
    /*                    */
    display: inline;
    cursor: pointer;

    position: relative;

    background: none;
    border: none;
    padding: 0;

    box-sizing: border-box;
    text-decoration: tokens.$oc-component-link-primary-text-decoration;
    color: tokens.$oc-component-link-primary-text-color;
    font: inherit;

    & {
      /*                                                                   */
      @include mixins.focus-styles(0.01px);
    }

    & {
      @include mixins.no-tap-highlight();
    }

    &--primary {
      color: tokens.$oc-component-link-primary-text-color;
    }

    &--secondary {
      color: tokens.$oc-component-link-secondary-text-color;
    }

    &--underlined,
    &--underlined-bold {
      color: tokens.$oc-component-link-underlined-text-color;
      text-decoration: tokens.$oc-component-link-underlined-text-decoration;
    }

    &--underlined-bold {
      font-weight: tokens.$oc-component-link-underlined-bold-font-weight;
    }
  }

  @media (hover: hover) {
    :host(:hover:not(:disabled)) .link {
      &--primary {
        text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
        color: tokens.$oc-component-link-primary-text-color-hover;
      }
      &--secondary {
        text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
        color: tokens.$oc-component-link-secondary-text-color-hover;
      }
      &--underlined,
      &--underlined-bold {
        color: tokens.$oc-component-link-underlined-text-color-hover;
      }
    }
  }

  :host(:active:not(:disabled)) .link {
    &--primary {
      text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
      color: tokens.$oc-component-link-primary-text-color-active;
    }
    &--secondary {
      text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
      color: tokens.$oc-component-link-secondary-text-color-active;
    }
    &--underlined,
    &--underlined-bold {
      color: tokens.$oc-component-link-underlined-text-color-active;
    }
  }
</style>
