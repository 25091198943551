/*                                     */
/*                                     */
import type { Class } from "type-fest";
import { sendLog } from "@otto-ec/global-resources/debug";

declare global {
  interface Window {
    /**
 *
 *
 */
    __components: {
      /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
      extend: typeof extendCustomElement;
    };
  }
}

/**
 *
 *
 */
export type CustomElement = Class<
  HTMLElement & {
    /**
 *
 */
    connectedCallback(): void;
    /**
 *
 */
    disconnectedCallback(): void;
    /**
 *
 */
    adoptedCallback(): void;
    /**
 *
 *
 *
 *
 */
    attributeChangedCallback(name: string, oldValue: string, newValue: string): void;
  }
> & {
  /**
 *
 */
  readonly observedAttributes: string[];
};

const samplingEnabled = Math.random() < 0.2;

function extendCustomElement({
  delegateFocus,
  formAssociated,
  namespace = "otto-components",
}: {
  /**
 *
 *
 */
  formAssociated?: true;
  /**
 *
 */
  delegateFocus?: true;
  /**
 *
 *
 */
  namespace?: string;
} = {}) {
  return (customElement: Class<HTMLElement>): CustomElement => {
    let result = class extends (customElement as CustomElement) {
      connectedCallback(): void {
        /*                                                                 */
        if (samplingEnabled && import.meta.env.PROD && !import.meta.env.STORYBOOK) {
          sendLog(namespace, { tag: this.tagName });
        }

        /*                                                                         */
        return super.connectedCallback();
      }
    };

    if (delegateFocus) {
      result = class extends result {
        constructor() {
          super();
          this.attachShadow({ mode: "open", delegatesFocus: true });
        }
      };
    }

    if (formAssociated) {
      result = class extends result {
        static formAssociated = true;

        internals: ElementInternals;

        constructor() {
          super();
          this.internals = this.attachInternals() as unknown as ElementInternals;
        }

        get form(): HTMLFormElement | null {
          return this.internals.form;
        }

        set form(value: unknown) {
          if (typeof value === "string") {
            this.setAttribute("form", value);
          }
        }

        formResetCallback(): void {
          /*                                                         */
          /*         */
          this.resetForm?.();
        }
      };
    }

    return result;
  };
}

window.__components ??= {} as never;
window.__components.extend = extendCustomElement;
