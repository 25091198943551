type UseMultiSelectionReturn = {
  /**
 *
 */
  destroy: () => void;
  /**
 *
 */
  mount: () => void;
};

/**
 *
 *
 *
 *
 */
export function useMultiSelection<
  ELEMENT extends HTMLElement & { checked?: boolean; name?: string },
>(self: ELEMENT): UseMultiSelectionReturn {
  const keydown = (event: KeyboardEvent): void => {
    /*              */
    if (event.code === "Space") {
      self.checked = !self.checked;

      event.stopPropagation();
      event.preventDefault();
    }
  };

  const click = (event: MouseEvent): void => {
    self.checked = !self.checked;
    event.stopPropagation();
    event.preventDefault();
  };

  return {
    destroy: () => {
      self.removeEventListener("keydown", keydown);
      self.removeEventListener("click", click);
    },
    mount: () => {
      self.tabIndex = 0;
      self.role = "checkbox";
      self.addEventListener("keydown", keydown);
      self.addEventListener("click", click);
    },
  };
}
