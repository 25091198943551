<svelte:options
  customElement={{
    tag: "oc-skeleton-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true, formAssociated: true }),
    props: {
      variant: { type: "String" },
      width: { type: "String" },
      height: { type: "String" },
      borderRadius: { type: "String", attribute: "border-radius" },
      hidden: { type: "Boolean" },
    },
  }}
/>

<script lang="ts">
  /*                                            */
  import { onMount } from "svelte";
  /*                                            */
  import { fade } from "svelte/transition";

  import type { Props } from "./SkeletonV1.types";

  let {
    variant = "rectangle",
    width = undefined,
    height = undefined,
    borderRadius = "8px",
    hidden = false,
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();

  let calculatedBorderRadius: string = $derived(
    variant === "circle" ? "50%" : borderRadius || "0px",
  );
  let calculatedWidth: string = $derived(width || "100%");
  let calculatedHeight: string = $derived(
    variant === "circle" ? width || "100%" : height || "100%",
  );

  onMount(() => {
    Host.setAttribute("tabindex", "-1");
    /*                       */
    internals.ariaHidden = "true";
  });
</script>

{#if !hidden}
  <div
    transition:fade={{ duration: 100 }}
    style:border-radius={calculatedBorderRadius}
    style:width={calculatedWidth}
    style:height={calculatedHeight}
    class="skeleton"
  ></div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    display: inline-block;
    width: inherit;
    height: inherit;
  }

  @keyframes loading {
    0% {
      left: 0;
      animation-timing-function: tokens.$oc-component-skeleton-wave-animation-easing;
    }
    #{calc(tokens.$oc-component-skeleton-wave-animation-duration / calc(tokens.$oc-component-skeleton-wave-animation-duration + tokens.$oc-component-skeleton-wave-animation-pause))
      *
      100
      + "%"},
    100% {
      left: calc(100% + tokens.$oc-component-skeleton-wave-width);
      animation-timing-function: linear;
    }
  }

  .skeleton {
    position: relative;
    overflow-x: hidden;
    width: inherit;
    height: inherit;
    min-width: 8px;
    min-height: 8px;
    background-color: tokens.$oc-component-skeleton-background-color;

    &:hover {
      cursor: tokens.$oc-component-skeleton-cursor;
    }

    &::after {
      animation: loading
        calc(
          tokens.$oc-component-skeleton-wave-animation-duration +
            tokens.$oc-component-skeleton-wave-animation-pause
        )
        infinite;
      position: absolute;
      opacity: 0.5; /*                                         */
      translate: -100%;
      top: 0;
      display: block;
      left: 0;
      content: "";
      height: 100%;
      width: #{tokens.$oc-component-skeleton-wave-width};
      background: tokens.$oc-component-skeleton-wave-color;
      pointer-events: none;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }

    &--hidden {
      transition: opacity 2s tokens.$oc-component-skeleton-content-animation-easing;
      opacity: 0;
    }
  }
</style>
