import { getAssociatedFormElements } from "./form/form";

type UseSingleSelectionReturn = {
  /**
 *
 */
  destroy: () => void;
  /**
 *
 */
  mount: () => void;
  /**
 *
 *
 *
 *
 */
  update: ({ name, checked }: { name?: string; checked: boolean }) => void;
};

/**
 *
 *
 *
 *
 */
export function useSingleSelection<
  ELEMENT extends HTMLElement & { checked?: boolean; name?: string },
>(self: ELEMENT): UseSingleSelectionReturn {
  /**
 *
 *
 */
  const keydown = (event: KeyboardEvent): void => {
    /*              */
    if (event.code === "Space") {
      if (!self.checked) {
        self.checked = true;
      }
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    /*                   */
    const selectPrevious = ["ArrowUp", "ArrowLeft"].includes(event.code);
    const selectNext = ["ArrowDown", "ArrowRight"].includes(event.code);
    if (!selectPrevious && !selectNext) {
      return;
    }

    const elements = getAssociatedFormElements<ELEMENT>(self);
    const currentIndex = Math.max(
      elements.findIndex((ele) => ele.checked),
      0,
    );

    let nextElement: ELEMENT | undefined;
    if (selectPrevious) {
      nextElement = elements[currentIndex - 1] ?? elements[elements.length - 1];
    } else if (selectNext) {
      nextElement = elements[currentIndex + 1] ?? elements[0];
    }

    if (nextElement) {
      nextElement.checked = true;
      nextElement.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  /**
 *
 *
 */
  const click = (event: MouseEvent): void => {
    self.checked = true;
    event.stopPropagation();
    event.preventDefault();
  };

  return {
    destroy: () => {
      self.removeEventListener("keydown", keydown);
      self.removeEventListener("click", click);
    },

    mount: () => {
      self.role = "radio";
      self.addEventListener("keydown", keydown);
      self.addEventListener("click", click);
    },

    update: ({ name, checked }: { name?: string; checked: boolean }) => {
      if (!name) return;

      const elements = getAssociatedFormElements<ELEMENT>(self);
      if (checked) {
        self.tabIndex = 0;

        /*                     */
        elements
          .filter((ele) => ele !== self)
          .forEach((ele) => {
            ele.tabIndex = -1;
            ele.checked = false;
          });
      } else if (elements[0] === self && elements.every((ele) => !ele.checked)) {
        /*                                                   */
        self.tabIndex = 0;
      } else {
        self.tabIndex = -1;
      }
    },
  };
}
