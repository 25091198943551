<svelte:options
  customElement={{
    tag: "oc-button-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
    }),
    props: {
      variant: { type: "String" },
      size: { type: "String", reflect: true },
      iconTypeLeft: { type: "String", attribute: "icon-type-left" },
      iconTypeRight: { type: "String", attribute: "icon-type-right" },
      fitContent: { type: "Boolean", attribute: "fit-content", reflect: true },
      disabled: { type: "Boolean", reflect: true },
      type: { type: "String" },
      loading: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      formMethod: { type: "String", attribute: "formmethod", reflect: true },
      formAction: { type: "String", attribute: "formaction", reflect: true },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
    },
  }}
/>

<script lang="ts">
  /*                                            */
  import { onMount } from "svelte";
  /*                                            */
  import { fade } from "svelte/transition";

  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { getDefaultSubmitter } from "../../../common/utils/form/form";

  import type { Props } from "./ButtonV1.types";
  import { requestSubmit } from "./requestSubmit";

  let {
    variant = "primary",
    size = "100",
    iconTypeLeft = undefined,
    iconTypeRight = undefined,
    fitContent = false,
    disabled = false,
    type = undefined,
    loading = false,
    ocAriaLabel = undefined,
    href = undefined,
    base64Href = undefined,
    formMethod = undefined,
    formAction = undefined,
    internals,
  } = $props<Props & { internals: ElementInternals }>();

  const Host = $host<HTMLOcButtonV1Element>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );

  const hasIconRight = $derived(iconTypeRight || loading);

  let button = $state<HTMLElement>();

  Host.addEventListener("click", (event) => {
    if (loading) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    if (type === "submit") {
      event.preventDefault();
      requestSubmit(internals.form, button!);
    } else if (type === "reset") {
      event.preventDefault();
      internals.form?.reset();
    }
  });

  onMount(() => {
    const formEnterHandler = (event: KeyboardEvent) => {
      /*                                                  */
      if (event.defaultPrevented) return;

      /*                    */
      if (event.key !== "Enter" || type !== "submit") return;

      const tagName = (event.target as HTMLElement | null)?.tagName;

      /*                              */
      if (tagName === "TEXTAREA") return;

      /*                          */
      /*                                       */
      if (tagName?.startsWith("OC-")) return;

      /*                                                                       */
      if (!Array.from(internals.form?.elements || []).some((e) => e === event.target)) return;

      /*                                                           */
      if (Host !== getDefaultSubmitter(internals.form)) return;

      event.preventDefault();
      /*                                                                               */
      /*                                                */
      Host.click();
    };
    internals.form?.addEventListener("keydown", formEnterHandler);
    return () => {
      internals.form?.removeEventListener("keydown", formEnterHandler);
    };
  });
</script>

<InteractiveElement
  asButton={true}
  bind:thisElement={button}
  bind:href
  bind:base64Href
  {disabled}
  {type}
  aria-label={ocAriaLabel}
  class={`button button--variant-${variant} button--size-${size} ${fitContent && size === "100" ? "button--fit-content" : ""} ${iconTypeLeft ? "button--has-icon-left" : ""} ${hasIconRight ? "button--has-icon-right" : ""} ${disabled ? "button--disabled" : ""} ${loading ? "button--is-loading" : ""}`}
  aria-disabled={loading || disabled}
  formmethod={formMethod}
  formaction={formAction}
>
  {#if iconTypeLeft}
    <oc-icon-v1 class="button__icon button__icon--left" type={iconTypeLeft} {size}></oc-icon-v1>
  {/if}

  <span>
    <slot />
  </span>

  {#if loading}
    <oc-spinner-v1
      in:fade={{ duration: 200 }}
      class="button__icon button__icon--right"
      variant={variant === "secondary" || variant === "tertiary" ? "default" : "inverted"}
      {size}
    ></oc-spinner-v1>
  {:else if iconTypeRight}
    <oc-icon-v1 class="button__icon button__icon--right" type={iconTypeRight} {size}></oc-icon-v1>
  {/if}
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $oc-component-button-100-span-spacing: calc(
    tokens.$oc-component-button-100-icon-size + tokens.$oc-component-button-100-icon-spacing-x
  );
  $oc-component-button-50-span-spacing: calc(
    tokens.$oc-component-button-50-icon-size + tokens.$oc-component-button-50-icon-spacing-x
  );

  :host {
    display: block;
  }

  :host([fit-content]),
  :host([size="50"]) {
    display: inline-block;
  }

  /*                                   */
  .button {
    align-items: center;
    border-radius: tokens.$oc-component-button-100-border-radius;
    border: none;
    box-sizing: border-box;
    display: inline-flex;
    font: tokens.$oc-component-button-100-primary-font;
    height: 48px;
    justify-content: center;
    margin: 0;
    outline: 0 none;
    padding: tokens.$oc-component-button-100-spacing-y
      tokens.$oc-component-button-100-default-spacing-x;
    position: relative;
    text-decoration: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;

    &--has-icon-left,
    &--has-icon-right {
      padding: tokens.$oc-component-button-100-spacing-y
        tokens.$oc-component-button-100-icon-spacing-x;

      > span {
        padding: 0 $oc-component-button-100-span-spacing;
      }
    }

    &--fit-content {
      width: fit-content;

      &.button--has-icon-left {
        > span {
          padding: 0 tokens.$oc-component-button-100-icon-spacing-x 0
            $oc-component-button-100-span-spacing;
        }
      }

      &.button--has-icon-right {
        > span {
          padding: 0 $oc-component-button-100-span-spacing 0
            tokens.$oc-component-button-100-icon-spacing-x;
        }
      }

      &.button--has-icon-left.button--has-icon-right {
        > span {
          padding: 0 $oc-component-button-100-span-spacing;
        }
      }
    }

    > .button__icon {
      height: tokens.$oc-component-button-100-icon-size;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: tokens.$oc-component-button-100-icon-size;

      &--left {
        left: tokens.$oc-component-button-100-icon-spacing-x;
      }

      &--right {
        left: auto;
        right: tokens.$oc-component-button-100-icon-spacing-x;
      }
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      white-space: nowrap;
    }

    & {
      @include mixins.focus-styles(tokens.$oc-component-button-100-border-radius);
    }

    &--variant-primary {
      background-color: tokens.$oc-component-button-primary-background-color;
      color: tokens.$oc-component-button-primary-text-color;

      > .button__icon {
        fill: tokens.$oc-component-button-primary-icon-color;
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-primary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-primary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &--variant-secondary {
      background-color: tokens.$oc-component-button-secondary-background-color;
      color: tokens.$oc-component-button-secondary-text-color;
      font: tokens.$oc-component-button-100-secondary-font;

      > .button__icon {
        fill: tokens.$oc-component-button-secondary-icon-color;
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-secondary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-secondary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &--variant-tertiary {
      background-color: tokens.$oc-component-button-tertiary-background-color;
      color: tokens.$oc-component-button-tertiary-text-color;
      font: tokens.$oc-component-button-100-tertiary-font;

      > .button__icon {
        fill: tokens.$oc-component-button-tertiary-icon-color;
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-tertiary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-tertiary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &:disabled {
      background-color: tokens.$oc-component-button-disabled-background-color;
      color: tokens.$oc-component-button-disabled-text-color;
      font: tokens.$oc-component-button-100-disabled-font;

      > .button__icon {
        fill: tokens.$oc-component-button-disabled-icon-color;
      }
    }

    &--size-50 {
      border-radius: tokens.$oc-component-button-50-border-radius;
      height: 32px;
      padding: tokens.$oc-component-button-50-spacing-y
        tokens.$oc-component-button-50-default-spacing-x;
      width: auto;

      & {
        @include mixins.focus-styles(tokens.$oc-component-button-50-border-radius);
      }

      &.button--has-icon-left {
        padding-top: tokens.$oc-component-button-50-spacing-y;
        padding-right: tokens.$oc-component-button-50-default-spacing-x;
        padding-bottom: tokens.$oc-component-button-50-spacing-y;
        padding-left: calc(tokens.$oc-component-button-50-default-spacing-x / 2);

        > span {
          padding: 0 0 0 $oc-component-button-50-span-spacing;
        }
      }

      &.button--has-icon-right {
        padding-top: tokens.$oc-component-button-50-spacing-y;
        padding-right: calc(tokens.$oc-component-button-50-default-spacing-x / 2);
        padding-bottom: tokens.$oc-component-button-50-spacing-y;
        padding-left: tokens.$oc-component-button-50-default-spacing-x;

        > span {
          padding: 0 $oc-component-button-50-span-spacing 0 0;
        }
      }

      &.button--has-icon-left.button--has-icon-right {
        padding: tokens.$oc-component-button-50-spacing-y
          calc(tokens.$oc-component-button-50-default-spacing-x / 2);

        > span {
          padding: 0 $oc-component-button-50-span-spacing;
        }
      }

      > .button__icon {
        height: tokens.$oc-component-button-50-icon-size;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: tokens.$oc-component-button-50-icon-size;

        &--left {
          left: tokens.$oc-component-button-50-icon-spacing-x;
        }

        &--right {
          left: auto;
          right: tokens.$oc-component-button-50-icon-spacing-x;
        }
      }

      &.button--variant-primary {
        font: tokens.$oc-component-button-50-primary-font;
      }

      &.button--variant-secondary {
        font: tokens.$oc-component-button-50-secondary-font;
      }

      &.button--variant-tertiary {
        font: tokens.$oc-component-button-50-tertiary-font;
      }

      &:disabled {
        font: tokens.$oc-component-button-50-disabled-font;
      }
    }

    &--is-loading {
      cursor: progress;
    }
  }
</style>
